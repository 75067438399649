<jacidi-admin-layout>
  <ng-container sidebar>
    <jacidi-sidebar [sidebardItems]="routes" />
  </ng-container>
  <ng-container header>
    <jacidi-admin-header>
      <ng-template appTemplate="logo">
        <img class="logo" src="logo.svg" alt="" />
      </ng-template>
      <ng-template appTemplate="actions">
        <app-restaurant-selector></app-restaurant-selector>
        <p-dropdown
          styleClass="primary"
          optionLabel="name"
          [options]="languages"
          [(ngModel)]="selectedLan"
          (onChange)="changeLan($event.value)">
          <ng-template pTemplate="selectedItem">
            {{ selectedLan!.name | translate }}
          </ng-template>
          <ng-template let-lan pTemplate="item">
            {{ lan.name | translate }}
          </ng-template>
        </p-dropdown>
      </ng-template>
      <ng-template appTemplate="profile">
        <jacidi-apps-selector [apps]="apps" />
        <jacidi-profile [user]="user()" (onLogout)="logout()" buttonLabel="LIB.PROFILE.LOG_OUT" />
      </ng-template>
    </jacidi-admin-header>
  </ng-container>
  <ng-container content>
    <router-outlet></router-outlet>
  </ng-container>
</jacidi-admin-layout>
