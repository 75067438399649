import { JacidiAppLink, JacidiAppLinkKey } from '@components/jacidi-components-lib';

export const AVAILABLE_APPS: JacidiAppLink[] = [
  {
    key: JacidiAppLinkKey.Cast,
    name: 'Cast',
    href: 'https://cast.eroomsuite.com/',
  },
  {
    key: JacidiAppLinkKey.Hotspot,
    name: 'Hotspot',
    href: 'https://hotspot.eroomsuite.com/',
  },
  {
    key: JacidiAppLinkKey.Butler,
    name: 'Butler',
    href: 'https://eroomsuite.com/butler',
  },
  {
    key: JacidiAppLinkKey.Crm,
    name: 'CRM',
    href: 'http://crm.fitur.eroomsuite.com',
  },
  {
    key: JacidiAppLinkKey.Pms,
    name: 'PMS',
    href: 'https://eroomsuite.com/pms',
  },
];
