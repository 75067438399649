import { APP_INITIALIZER, ApplicationConfig, Provider } from '@angular/core';
import { provideRouter, withComponentInputBinding, withViewTransitions } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { ApiModule, Configuration } from '@eroom/dam-angular-client';

import { environment } from '../environments/environment';
import { ROOT_ROUTES } from './app.routes';
import { DamService } from './shared/services/dam.service';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        onLoad: 'check-sso', // Action to take on load
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
    });
}

// Provider for Keycloak Bearer Interceptor
const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true,
};

// Provider for Keycloak Initialization
const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService],
};

function apiDamConfigFactory(): Configuration {
  return new Configuration({
    basePath: environment.apiDamUrl,
  });
}

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    KeycloakInitializerProvider,
    KeycloakBearerInterceptorProvider,
    KeycloakService,
    DamService,
    provideRouter(ROOT_ROUTES, withViewTransitions(), withComponentInputBinding()),
    ApiModule.forRoot(apiDamConfigFactory).providers!,
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem('lan') ?? 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }).providers!,
    provideAnimations(),
  ],
};
