<p-dropdown
  styleClass="primary header-selector"
  optionLabel="name"
  [placeholder]="'Select restaurant' | translate"
  [filter]="true"
  filterBy="name"
  [options]="restaurants()"
  [ngModel]="restaurantSelected"
  (onChange)="onChangeSelection($event.value)">
</p-dropdown>
