import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, signal, untracked } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Restaurant } from '../../models/restaurant.model';
import { RestaurantService } from '../../services/restaurant.service';


@Component({
  selector: 'app-restaurant-selector',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    TranslateModule,
  ],
  templateUrl: './restaurant-selector.component.html',
  styleUrl: './restaurant-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantSelectorComponent {
  private readonly _restaurantService = inject(RestaurantService);

  protected siteIds = signal<string[]>([]);
  protected restaurants = signal<Restaurant[]>([]);
  public restaurantSelected: Restaurant | null = null;

  constructor() {
    effect(() => {
      const restLists = this._restaurantService.restaurantsListSignal();
      const restSelected = this._restaurantService.restaurantSelectedSignal();
      untracked(() => {
        if (restLists.length > 0) {
          this.restaurants.set(restLists);
          this.restaurantSelected = restSelected;
        }
      });
    });
  }

  onChangeSelection(value: Restaurant) {
    this._restaurantService.changeRestaurant(value);
  }

}
