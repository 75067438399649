import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { SessionService } from './core/services/session.service';
import { RestaurantService } from './shared/services/restaurant.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private readonly _sessionService = inject(SessionService);
  private readonly _restaurantService = inject(RestaurantService);

  ngOnInit(): void {
    this._sessionService.setUser();
  }
}
